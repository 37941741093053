<template>
    <div class="loading-indicator">
      <div class="loading-indicator__content">
        <div class="bb-spinner">
          <div class="bb-spiner__content">
            <div class="bb-spinner__dots">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="bb-spinner__title">
              {{ $t("generic.redirecting") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
        console.log('mounted redirect')
        //url comes in 'd' query param
        const url = this.$route.query.d;
        const decodedUrl = decodeURIComponent(url);
        window.location.href = decodedUrl;
    }
  }
  </script>